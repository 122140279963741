/*!
 * Start Bootstrap - New Age v5.0.6 (https://startbootstrap.com/template-overviews/new-age)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-new-age/blob/master/LICENSE)
 */

body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: Muli, Helvetica, Arial, sans-serif;
}

a {
  color: #fdcc52;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

a:focus,
a:hover {
  color: #fcbd20;
}

hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
  border-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: Catamaran, Helvetica, Arial, sans-serif;*/
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}

#test {
  /* width: 595px;
  height: 842px;*/
  background-color: "red";
} /*
.innercontainer {

  width: 595 / 2.928, height: 842 / 5, background: "blue"
}*/
p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

section {
  padding: 100px 0;
}

section h2 {
  font-size: 50px;
}

#mainNav {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #fff;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand {
  color: #fdcc52;
  font-family: Catamaran, Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}

#mainNav .navbar-brand:focus,
#mainNav .navbar-brand:hover {
  color: #fcbd20;
}

#mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #222;
}

#mainNav .navbar-nav > li > a {
  font-size: 11px;
  font-family: Lato, Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#mainNav .navbar-nav > li > a.active {
  color: #fdcc52 !important;
  background-color: transparent;
}

#mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#mainNav .navbar-nav > li > a,
#mainNav .navbar-nav > li > a:focus {
  color: #222;
}

#mainNav .navbar-nav > li > a:focus:hover,
#mainNav .navbar-nav > li > a:hover {
  color: #fdcc52;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: fade(white, 70%);
  }
  #mainNav .navbar-brand:focus,
  #mainNav .navbar-brand:hover {
    color: #fff;
  }
  #mainNav .navbar-nav > li > a,
  #mainNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav > li > a:focus:hover,
  #mainNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #fff;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #222;
  }
  #mainNav.navbar-shrink .navbar-brand:focus,
  #mainNav.navbar-shrink .navbar-brand:hover {
    color: #fdcc52;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a,
  #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #222;
  }
  #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover,
  #mainNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #fdcc52;
  }
}

header.masthead {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;

  background: #311c92;
}

header.masthead .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

header.masthead .header-content h1 {
  font-size: 30px;
}

header.masthead .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead .device-container .screen img {
  border-radius: 3px;
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead .header-content {
    margin-bottom: 0;
    text-align: left;
  }
  header.masthead .header-content h1 {
    font-size: 50px;
  }
  header.masthead .device-container {
    max-width: 325px;
  }
}

header.masthead_login {
  position: relative;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  color: #fff;
  background: #311c92;
}

header.masthead_login .header-content {
  max-width: 500px;
  margin-bottom: 100px;
  text-align: center;
}

header.masthead_login .header-content h1 {
  font-size: 30px;
}

header.masthead_login .device-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

header.masthead_login .device-container .screen img {
  border-radius: 3px;
}

@media (min-width: 992px) {
  header.masthead_login {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead_login .header-content {
    margin-bottom: 0;
    text-align: left;
  }
  header.masthead_login .header-content h1 {
    font-size: 50px;
  }
  header.masthead_login .device-container {
    max-width: 325px;
  }
}

section.download {
  position: relative;
  padding: 150px 0;
}

section.download h2 {
  font-size: 50px;
  margin-top: 0;
}

section.download .badges .badge-link {
  display: block;
  margin-bottom: 25px;
}

section.download .badges .badge-link:last-child {
  margin-bottom: 0;
}

section.download .badges .badge-link img {
  height: 60px;
}

@media (min-width: 768px) {
  section.download .badges .badge-link {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  section.download h2 {
    font-size: 70px;
  }
}

section.features .section-heading {
  margin-bottom: 100px;
}

section.features .section-heading h2 {
  margin-top: 0;
}

section.features .section-heading p {
  margin-bottom: 0;
}

section.features .device-container,
section.features .feature-item {
  max-width: 325px;
  margin: 0 auto;
}

section.features .device-container {
  margin-bottom: 100px;
}

@media (min-width: 992px) {
  section.features .device-container {
    margin-bottom: 0;
  }
}

section.features .feature-item {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

section.features .feature-item h3 {
  font-size: 30px;
}

section.features .feature-item i {
  font-size: 80px;
  display: block;
  margin-bottom: 15px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#7b4397),
    to(#dc2430)
  );
  background: linear-gradient(to left, #7b4397, #dc2430);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.cta {
  position: relative;
  padding: 250px 0;
  /* background-image: url(../img/bg-cta.jpg);*/
  background-position: center;
  background-size: cover;
}

section.cta .cta-content {
  position: relative;
  z-index: 1;
}

section.cta .cta-content h2 {
  font-size: 50px;
  max-width: 450px;
  margin-top: 0;
  margin-bottom: 25px;
  color: #fff;
}

@media (min-width: 768px) {
  section.cta .cta-content h2 {
    font-size: 80px;
  }
}

section.cta .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

section.contact {
  text-align: center;
}

section.contact h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

section.contact h2 i {
  color: #dd4b39;
}

section.contact ul.list-social {
  margin-bottom: 0;
}

section.contact ul.list-social li a {
  font-size: 40px;
  line-height: 80px;
  display: block;
  width: 80px;
  height: 80px;
  color: #fff;
  border-radius: 100%;
}

section.contact ul.list-social li.social-twitter a {
  background-color: #1da1f2;
}

section.contact ul.list-social li.social-twitter a:hover {
  background-color: #0d95e8;
}

section.contact ul.list-social li.social-facebook a {
  background-color: #3b5998;
}

section.contact ul.list-social li.social-facebook a:hover {
  background-color: #344e86;
}

section.contact ul.list-social li.social-google-plus a {
  background-color: #dd4b39;
}

section.contact ul.list-social li.social-google-plus a:hover {
  background-color: #d73925;
}

footer {
  padding: 25px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222;
}

footer p {
  font-size: 12px;
  margin: 0;
}

footer ul {
  margin-bottom: 0;
}

footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

footer ul li a.active,
footer ul li a:active,
footer ul li a:focus,
footer ul li a:hover {
  text-decoration: none;
}

.bg-primary {
  background: #fdcc52;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdcc52),
    to(#fdc539)
  );
  background: linear-gradient(#fdcc52, #fdc539);
}

.text-primary {
  color: #fdcc52;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.btn-outline_1 {
  color: #fff;
  border: 1px solid;
  border-color: #fff;
}

.btn-outline_1.active,
.btn-outline_1:active,
.btn-outline_1:focus,
.btn-outline_1:hover {
  color: #fff;
  border-color: #fdcc52;
  background-color: #fdcc52;
  cursor: pointer;
}

.btn_1 {
  border-radius: 300px;
  font-family: Lato, Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn-xl_1 {
  font-size: 11px;
  padding: 15px 45px;
}

.my-qr-code-cell {
  opacity: 1; /*0.8;*/
}

.my-qr-code-cell-empty {
  fill: white; /*lightgray;*/
}

.my-qr-code-cell-filled {
  fill: black; /*brown;*/
}

.size_qr {
  /* width: 30vh;*/
  /* width: 50vh;*/
  /* width: 30vh;*/
  background: white;
}

.sie_qr {
  /* width: 30vh;*/
  /* width: 50vh;*/
  /* width: 30vh;*/
  background: red;
}

.countdown_and_draw_style {
  /*width: 50vh;*/
  background: white;
}

.app-store-logo {
  height: 6vmin;
  pointer-events: none;
  color: white;
}
